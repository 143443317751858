import joystick from '@joystick.js/ui-canary';
import search from '../../icons/search.svg';
import chevron_right from '../../icons/chevron-right.svg';

const Components = joystick.component({
  data: async (api = {}, req = {}, input = {}, instance = {}) => {
    return {
      components: await api.get('components')
    };
  },
  css: {
    min: {
      width: {
        0: `
          .components {
            margin-top: 25px;
          }

          .components .search-input {
            max-width: 690px;
            margin: 0 auto 100px;
          }


          .components .search-input > svg {
            top: 23px;
            left: 24px;
          }

          .components .search-input > .input {
            border-radius: 30px;
            font-size: 16px;
            padding: 20px 20px 20px 50px;
            background: linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(0,0,0,0) 100%);
          }

          .components-list {
            margin-top: 20px;
          }

          .components-list > li {
            display: flex;
            flex-direction: column;
            text-decoration: none;
            font-size: 15px;
            font-weight: 500;
            color: #555;
            position: relative;
          }

          .components-list > li:not(:last-child) {
            margin-bottom: 10px;
          }
          
          .components-list > li a {
            position: absolute;
            inset: 0;
            z-index: 2;
          }

          .components-list > li a svg {
            width: 8px;
            height: auto;
            margin-left: auto;
          }

          .components-list > li a svg path {
            fill: #aaa;
          }

          .components-list > li a:hover {
            color: var(--brand);
          }

          .components-list > li a:hover svg path {
            fill: var(--brand);
          }

          .components-list > li > .component-screenshot {
            border: 1px solid #eee;
            border-radius: 5px;
            background: #fff;
            position: relative;
            margin: 0 0 10px;
            padding: 0;
            aspect-ratio: 16/9;
            overflow: hidden;
            box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.01);
          }

          .components-list > li > .component-screenshot img {
            max-width: 100%;
          }

          .components-list > li h2 {
            display: flex;
            align-items: center;
            font-size: 15px;
            font-weight: 400;
            color: #333;
          }

          .components-list > li:hover h2 {
            color: var(--brand);
          }

          .components-list > li .plus-badge {
            display: inline-block;
            margin-left: auto;
            background: rgba(var(--success-rgb), 0.1);
            border: 1px solid rgba(var(--success-rgb), 0.5);
            color: var(--success);
            padding: 4px 6px;
            border-radius: 30px;
            font-size: 11px;
            line-height: 11px;
            font-weight: 600;
            text-transform: uppercase;
          }

          .components-list > li p {
            display: none;
            color: #aaa;
            font-size: 14px;
          }
        `,
        740: `
          .components .components-list {
            display: grid;
            gap: 30px;
            grid-template-columns: repeat(2, 1fr);
            margin-top: 0px;
          }        
        `,
        940: `
          .components .components-list {
            display: grid;
            gap: 30px;
            grid-template-columns: repeat(3, 1fr);
            margin-top: 0px;
          }        
        `,
        1040: `
          .components {
            margin-top: 45px;
          }

          .components > main {
            display: flex;
          }

          .components > main > aside {
            width: 18%;
            margin-right: 8%;
          }

          .components > main > aside h5 {
            font-weight: 600;
            margin-bottom: 15px;
            font-size: 15px;
          }

          .components > main > aside ul:not(:last-child) {
            margin-bottom: 40px;
          }

          .components > main > aside ul li {
            display: flex;
            align-items: center;
            font-size: 15px;
            color: #555;
            cursor: pointer;
          }

          .components > main > aside ul li:hover {
            color: var(--brand);
          }

          .components > main > aside ul li:not(:last-child) {
            margin-bottom: 5px;
          }

          .components > main > aside ul li span {
            display: inline-block;
            margin-left: auto;
            padding: 4px 5px;
            border-radius: 30px;
            background: #eee;
            font-size: 12px;
            line-height: 12px;
            font-weight: 600;
            color: #555;
          }

          .components > main > .search-results {
            width: 74%;          
          }

          .components > main > .search-results > header {
            margin-bottom: 30px;
          }
          
          .components > main > .search-results .selected-filters li {
            display: inline-flex;
            align-items: center;
            font-size: 14px;
            font-weight: 500;
            border: 1px solid #eee;
            border-radius: 30px;
            padding: 3px 8px 3px 13px;
            color: #555;
          }

          .components > main > .search-results .selected-filters {
            display: flex;
            flex-wrap: wrap;
            gap: 10px;
          }

          .components > main > .search-results .selected-filters li .icon-x {
            font-size: 16px;
            font-weight: 600;
            color: #aaa;
            margin-left: 5px;
            cursor: pointer;
          }

          .components > main > .search-results .selected-filters li .icon-x:hover {
            color: var(--danger);
          }

          .components .components-list {
            display: grid;
            gap: 30px;
            grid-template-columns: repeat(3, 1fr);
            margin-top: 0px;
          }

          .components .components-list > li {
            transition: transform 0.3s ease-in-out;
            margin: 0;
          }

          .components .components-list > li > .component-screenshot {
            display: block;
          }
        `,
        1240: `
          .components {
            margin-top: 75px;
          }
        `,        
      },
    },
  },
  render: ({ props, state, data, each }) => {
    return `
      <div class="components">
        <div class="search-input">
          ${search}
          <input type="text" class="input" placeholder="What component do you need?" />
        </div>
        <main>
          <aside>
            <h5>Type</h5>
            <ul>
              <li>Free <span>29</span></li>
              <li>Plus <span>29</span></li>
            </ul>
            <h5>Category</h5>
            <ul>
              <li>Forms <span>29</span></li>
              <li>Information <span>29</span></li>
              <li>Navigation <span>29</span></li>
            </ul>
            <h5>Industry</h5>
            <ul>
              <li>Legal <span>29</span></li>
              <li>Medical <span>29</span></li>
              <li>SaaS <span>29</span></li>
            </ul>            
          </aside>
          <div class="search-results">
            <header>
              <ul class="selected-filters">
                <li>Industry: Medical <i class="icon-x"></i></li>
                <li>Industry: Technology <i class="icon-x"></i></li>
                <li>Industry: Finance <i class="icon-x"></i></li>
                <li>Industry: Manufacturing <i class="icon-x"></i></li>
                <li>Industry: Retail <i class="icon-x"></i></li>
                <li>Industry: Education <i class="icon-x"></i></li>
                <li>Industry: Hospitality <i class="icon-x"></i></li>
                <li>Industry: Energy <i class="icon-x"></i></li>
                <li>Industry: Transportation <i class="icon-x"></i></li>
                <li>Industry: Construction <i class="icon-x"></i></li>
                <li>Industry: Agriculture <i class="icon-x"></i></li>
                <li>Industry: Entertainment <i class="icon-x"></i></li>
              </ul>
            </header>
            <ul class="components-list">
              ${each(data?.components, (component = {}, index = 0) => {
                return `<li>
                  <a href="${component?.url}"></a>
                  <div class="component-screenshot">
                    <img src="/component_screenshots/${component.id}_light.webp" alt="${component.name}" />
                  </div>
                  <h2>${component?.name} ${component?.plus ? '<span class="plus-badge">Plus</span>' : ''}</h2>
                  <p>${component?.styles?.length} ${component?.styles?.length === 1 ? 'Style' : 'Styles'}</p>
                </li>`;
              })}
            </ul>
          </div>
        </main>
      </div>
    `;
  },
});

if (
      typeof window !== 'undefined' &&
      window.__joystick_should_auto_mount__ === true &&
      !window.__joystick_layout_url__ &&
      window.__joystick_page_url__ &&
      !window.__joystick_hmr_update__ &&
      joystick &&
      joystick.mount
    ) {
      joystick.mount(Components, window.__joystick_ssr_props__ || {}, document.getElementById('app'));
    }

    export default Components;
    